/* BOILERPLATE */

@import url('https://use.typekit.net/iah6leo.css');

html {
  --accent-color: #ff7777;
}

body {
  background: #111;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* BUTTONS */

button, .read-more-button {
  background: transparent;
  border: none;
  color: var(--accent-color);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  padding: 0.3em 0.75em 0.45em;
  position: relative;
}

button::before,
button::after,
.read-more-button::before,
.read-more-button::after {
  content: '';
  display: block;
  position: absolute;
  width: 98.5%;
  height: 94%;
  border: 1px solid;
  transition: all 0.3s ease;
  border-radius: 2px;
}

button::before,
.read-more-button::before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: var(--accent-color);
  border-left-color: var(--accent-color);
}

button::after,
.read-more-button::after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: var(--accent-color);
  border-right-color: var(--accent-color);
}

button:hover:before,
button:hover:after,
.read-more-button:hover:before,
.read-more-button:hover:after,
.section-button:hover .read-more-button:after,
.section-button:hover .read-more-button:before {
  width: 25%;
  height: 25%;
}

.read-more-button {
  margin-right: 1em;
  margin-top: 1em;
}

.h2-button {
  color: #eee;
  margin-block-end: 0.83em;
  padding: 0;
}

.h2-button::before,
.h2-button::after,
.link-button::before,
.link-button::after {
  display: none;
}

:focus {
  outline: none;
}

:focus-visible {
  box-shadow: 0 0 5px var(--accent-color);
  outline: none;
}

.link-button {
  cursor: pointer;
  margin-left: auto;
  padding: 0;
}

/* IMAGES */

figure {
  margin: 2em 0 4em;
}

figcaption {
  background-color: #222;
  border: 1px solid #333;
  border-radius: 0 0 0.5em 0.5em;
  margin-top: -5px;
  padding: 1em 1.5em;
}

@media only screen and (max-width: 700px) {
  figcaption {
    padding: 0.75em;
  }
}

img, video {
  width: 100%;
}

.mobile-image, figcaption.mobile-figcaption {
  max-width: 350px;
}

.headshot {
  border-radius: 50%;
  filter: grayscale(20%);
  margin-top: 3em;
  width: 120px;
}

.copy-icon {
  display: none;
  margin-left: 0.5em;
  width: 0.85em;
}

@media (hover: none) {
  .copy-icon {
    display: inline;
  }
}

.case-study-preview-image {
  max-width: 300px;
}

Link:hover .case-study-preview-image {
  max-width: 310px;
}

iframe {
  overflow: hidden;
  height: 604px;
  width: 604px;
}

iframe.youtube {
  height: 378px;
  width: 670px;
  max-width: 100%;
}

/* LAYOUT */

* {
  box-sizing: border-box;
  color: #eee;
  font-family: halyard-text, sans-serif;
  font-weight: 400;
}

.page-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.text-wrapper {
  max-width: 750px;
}

.hero-wrapper {
  display: flex;
  height: 100vh;
  justify-content: space-around;
  align-items: center;
  margin-left: calc(-50vw + 600px);
  width: 100vw;
}

@media only screen and (max-width: 1200px) {
  .hero-wrapper {
    margin-left: 0;
  }
}

@media only screen and (max-width: 700px) {
  .hero-wrapper {
    height: 70vh;
  }
}

.hero {
  max-width: 800px;
}

@media only screen and (max-width: 700px) {
  .hero {
    max-width: 80vw;
  }
}

.cyan {
  background: #29abe2;
  background: linear-gradient(111deg, rgba(82,200,250,1) 0%, rgba(41,171,226,1) 35%, rgba(29,145,194,1) 100%);
}

.green {
  background: var(--light-green);
}

.orange {
  background: #e86b1d;
  background: linear-gradient(111deg, rgba(255,169,115,1) 0%, rgba(232,107,29,1) 35%, rgba(244,132,62,1) 100%);
}

.purple {
  background: #5744d2;
  background: linear-gradient(111deg, rgba(132,114,255,1) 0%, rgba(87,68,210,1) 35%, rgba(85,60,246,1) 100%);
}

.yellow {
  background: #f8df00;
}

.section-wrapper {
  padding: 50px 100px 75px;
  display: flex;
}

@media only screen and (max-width: 1100px) {
  .section-wrapper {
    padding: 50px 40px 75px;
    flex-direction: column;
  }

  .section-wrapper.reverse-on-small-screens {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 700px) {
  .section-wrapper {
    padding: 50px 20px 75px;
    flex-direction: column;
  }
}

.flex-wrapper {
  display: flex;
}

@media only screen and (max-width: 700px) {
  .flex-wrapper {
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .hide-on-mobile {
    display: none;
  }
}

@media only screen and (min-width: 701px) {
  .hide-on-non-mobile {
    display: none;
  }
}

.horizontally-padded-wrapper {
  padding: 0 100px 0;
}

@media only screen and (max-width: 1100px) {
  .horizontally-padded-wrapper {
    padding: 0 40px 0;
  }
}

@media only screen and (max-width: 700px) {
  .horizontally-padded-wrapper {
    padding: 0 20px 0;
  }
}

.vertically-padded-wrapper {
  padding: 50px 0 75px;
}

.bottom-padded-wrapper {
  padding-bottom: 50px;
}

.top-padded-wrapper {
  padding-top: 25px;
}

.left-column, .right-column {
  padding-right: 30px;
  width: 50%;
}

.width-80 {
  width: 80%;
}

.width-70 {
  width: 70%;
}

.width-65 {
  width: 65%;
}

.width-60 {
  width: 60%;
}

.width-40 {
  width: 40%;
}

.width-35 {
  width: 35%;
}

.width-30 {
  width: 30%;
}

.width-20 {
  width: 20%;
}

@media only screen and (max-width: 1100px) {
  .left-column, .right-column {
    width: auto;
  }
}

hr {
    border-style: solid;
    margin-block-end: 0;
    margin-block-start: 50px;
    margin-inline-start: 0;
}

/* NAV */

nav {
  margin: 0 100px;
}

@media only screen and (max-width: 1100px) {
  nav {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  nav {
    margin: 0 20px;
  }
}

.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
}

.nav-link {
 margin-right: 2em;
 position: relative;
}

@media only screen and (max-width: 700px) {
  .nav-link {
    margin-right: 1.5em;
   }
}

.nav-link-active li::before {
  background: var(--accent-color);
  border-radius: 50%;
  content: '';
  height: 7px;
  left: -0.75em;
  position: absolute;
  top: 0.5em;
  width: 7px;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
  transition: all 0.2s ease;
}

@media only screen and (max-width: 700px) {
  .nav-link-active .nav-link {
    font-weight: 700;
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-underline-offset: 0.3em;
  }

  .nav-link-active li::before {
    display: none;
  }
}


@media only screen and (min-width: 700px) {
  /* Style background for "you are here" marker */
  nav:hover .nav-link-active li::before {
    background: var(--accent-color);
    border-bottom-left-radius: 90% 50%;
    border-bottom-right-radius: 0%;
    border-top-left-radius: 60%;
    border-top-right-radius: 50% 80%;
    content: '';
    height: 50px;
    left: 0;
    position: absolute;
    top: 2em;
    width: 50px;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
  }

  /* Style text for "you are here" marker */
  nav:hover .nav-link-active li::after {
    color: #111;
    content: 'You are here';
    font-size: 14px;
    font-weight: 500;
    left: 10px;
    line-height: 0.85;
    position: absolute;
    text-align: center;
    top: 2.9em;
    width: 30px;
  }
}

a {
  color: var(--accent-color);
  font-size: 18px;
  text-decoration: none;
}

/* TYPOGRAPHY */

strong {
  font-weight: 700;
}

.callout {
  font-size: 76.246px;
  line-height: 1.2em;
  margin-block-start: 0em;
}

@media only screen and (max-width: 1100px) {
  .callout {
    font-size: 40px;
  }
}

h1 {
  font-family: halyard-display, sans-serif;
  font-size: 47.123px;
  font-weight: 500;
}

@media only screen and (max-width: 1100px) {
  h1 {
    font-size: 29.124px;
  }
}

@media only screen and (max-width: 700px) {
  h1 {
    font-size: 24.809px;
  }
}

h2, .h2-button, .h2-link {
  font-family: halyard-display, sans-serif;
  font-size: 29.124px;
  font-weight: 500;
}

blockquote {
  font-size: 29.124px;
  font-style: italic;
  font-weight: 400;
}

@media only screen and (max-width: 1100px) {
  h2, .h2-button, .h2-link, blockquote {
    font-size: 22.896px;
  }
}

@media only screen and (max-width: 700px) {
  h2, .h2-button, .h2-link, blockquote {
    font-size: 21.132px;
  }
}

p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

li {
  font-size: 18px;
}

dl {
  margin-bottom: 0;
}

dt {
  font-size: 18px;
  margin: 1.25em 0 0.1em;
}

dd {
  font-family: halyard-display, sans-serif;
  font-size: 22.896px;
  font-weight: 500;
  margin-left: 0;
}

@media only screen and (max-width: 1100px) {
  dd {
    font-size: 21.132px;
  }
}

.light-gray-text {
  color: #aaa;
}

.section-label {
  color: #aaa;
  padding-left: 20px;
}

/* SPECIAL COMPONENTS */

.announcement {
  background-color: var(--accent-color);
  border-radius: 0.25em;
  color: #111;
  font-size: 18px;
  padding: 1em;
  margin: 50px 100px 0;
}

@media only screen and (max-width: 1100px) {
  .announcement {
    margin: 50px 40px 0;
  }
}

@media only screen and (max-width: 700px) {
  .announcement {
    margin: 50px 20px 0;
  }
}

.availability-wrapper {
  font-size: 18px;
  /* font-style: italic; */
  margin: 2em 0 0;
}

.has-tooltip {
  position: relative;
}

.tooltip {
  left: 0;
  position: absolute;
  top: 1.5em;
  visibility: hidden;
  z-index: 1;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
}

@media (hover: none) {
  .has-tooltip:hover .tooltip {
    visibility: hidden;
  }
}